
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { CacheProvider, EmotionCache } from '@emotion/react';
import { config } from '@fortawesome/fontawesome-svg-core';
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { DehydratedState, Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ClientError } from 'graphql-request';
import { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import { Poppins } from 'next/font/google';
import Head from 'next/head';
import { useEffect, useState } from 'react';
import './styles.css';
import '@unreserved-frontend-v2/ui/light-gallery/light-gallery.css';
import '@fortawesome/fontawesome-svg-core/styles.css';
import '@unreserved-frontend-v2/ui/spinner/spinner.css';
import { getErrorCode } from '@unreserved-frontend-v2/api/fetchers/utils';
import { UserCookieValue } from '@unreserved-frontend-v2/modules/users/components/user-info-provider/user-info-provider';
import { useScrollRestoration } from '@unreserved-frontend-v2/ui/hooks/use-scroll-resortation/use-scroll-restoration';
import { muiTheme } from '@unreserved-frontend-v2/ui/theme/mui-theme';
import { createEmotionCache } from '@unreserved-frontend-v2/ui/utils/create-emotion-cache';
import { initDatadogRum } from '@unreserved-frontend-v2/utils/datadog/init';
import { featureEnabled } from '@unreserved-frontend-v2/utils/feature-flags';
import { HAVE_WINDOW } from '@unreserved-frontend-v2/utils/window';
const customFont = Poppins({
    subsets: ['latin'],
    weight: ['400', '600', '700', '800']
});
const ThirdPartyCSSWrapper = dynamic(() => import('../components/pages/global-dynamic-styles'));
const PageTemplate = dynamic(() => import('../components/pages/page-template'));
const GoogleAnalyticsScript: React.ElementType = dynamic(() => import('@unreserved-frontend-v2/modules/analytics/google-tag-manager/script').then((mod) => mod.GoogleAnalyticsScript));
const UserInfoProvider: React.ElementType = dynamic(() => import('@unreserved-frontend-v2/modules/users/components/user-info-provider/user-info-provider').then((mod) => mod.UserInfoProvider));
const ErrorHandler: React.ElementType = dynamic(() => import('@unreserved-frontend-v2/modules/errors/error-handler').then((mod) => mod.ErrorHandler));
const FacebookPixelScript: React.ElementType = dynamic(() => import('@unreserved-frontend-v2/modules/analytics/facebook').then((mod) => mod.FacebookPixelScript));
const MSClarityScript: React.ElementType = dynamic(() => import('@unreserved-frontend-v2/modules/analytics/clarity').then((mod) => mod.MSClarityScript));
const ErrorBoundary: React.ElementType = dynamic(() => import('@unreserved-frontend-v2/ui/error-boundary').then((mod) => mod.ErrorBoundary));
const QUERIES_TO_INVALIDATE_ON_USER_UPDATE = [
    // Batched query
    'GetListings',
    // Unbatched queries
    'GetListingsListResults',
    'GetListingsMetadata',
    'GetListingsMapMetadata',
    // Client-side only queries
    'GetListingsComparablesTypes',
    'GetUser',
    'GetFacetCounts',
    // Details view
    'GetListingLite',
    // Map
    'GetClusters',
    'GetClustersListings',
    // Home
    'GetHomeListings',
];
const theme = createTheme(muiTheme);
config.autoAddCss = false;
// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();
if (featureEnabled(process.env.NEXT_PUBLIC_API_MOCKING)) {
    // dynamic import to avoid bundling in production code
    import('@unreserved-frontend-v2/modules/msw').then((utils) => {
        utils.startMockServer();
    });
}
// Datadog RUM.
if (HAVE_WINDOW && featureEnabled(process.env.NEXT_PUBLIC_DD_ENABLE)) {
    initDatadogRum({ forTesting: false });
}
export type AppPageProps = {
    errorCode?: number;
    dehydratedState?: DehydratedState;
    cookies: UserCookieValue;
};
function CustomApp({ Component, pageProps, router, emotionCache = clientSideEmotionCache, }: AppProps<AppPageProps> & {
    emotionCache?: EmotionCache;
}) {
    const [errorCode, setErrorCode] = useState<number | undefined>(pageProps.errorCode);
    useEffect(() => {
        /**
         * When the pageProps change it means that we are on a new page. We set the ErrorCode here based on
         * the new props. If there isn't an error on the new page, errorCode will be undefined and this effectively
         * sets errorCode back to its default state (e.g. - no error) and the new page will render as normal.
         */
        setErrorCode(pageProps.errorCode);
    }, [pageProps]);
    const [queryClient] = useState(() => new QueryClient({
        defaultOptions: { queries: { refetchOnWindowFocus: false, staleTime: 60000, retry: 0 } }
    }));
    useScrollRestoration(router);
    useEffect(() => {
        queryClient.setDefaultOptions({
            queries: {
                refetchOnWindowFocus: false,
                staleTime: 5000,
                retry: 0,
                onError: (e: unknown) => {
                    setErrorCode(getErrorCode(e as ClientError));
                }
            },
            mutations: {
                retry: 0,
                onError: (e: unknown) => {
                    setErrorCode(getErrorCode(e as ClientError));
                }
            }
        });
    }, [queryClient]);
    return (<>
      <style jsx={true} global={true}>
        {`
          :root {
            --font-poppins: ${customFont.style.fontFamily};
          }
        `}
      </style>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <StyledEngineProvider injectFirst={true}>
            <CacheProvider value={emotionCache}>
              <Head>
                <meta name="viewport" content="initial-scale=1, width=device-width, maximum-scale=1"/>
                <meta name="theme-color" content="white"/>
                <meta name="msapplication-navbutton-color" content="white"/>
                <meta name="apple-mobile-web-app-capable" content="yes"/>
                <meta name="apple-mobile-web-app-status-bar-style" content="white"></meta>
                {/*
         * Disabling auto-phone detection because it causes hydration errors on mobile, use
         * `tel:XXX-XXX` links instead intentionally
         *
         * https://stackoverflow.com/a/227238
         */}
                <meta name="format-detection" content="telephone=no"/>
              </Head>
              <GoogleAnalyticsScript />
              {process.env['NEXT_PUBLIC_FB_ENABLE'] === 'enabled' ? <FacebookPixelScript /> : null}
              {process.env['NEXT_PUBLIC_CLARITY_ENABLE'] === 'enabled' ? <MSClarityScript /> : null}
              <ThemeProvider theme={theme}>
                <main className="app">
                  <UserInfoProvider serverCookies={pageProps.cookies} invalidateOnUserUpdate={QUERIES_TO_INVALIDATE_ON_USER_UPDATE}>
                    <ErrorBoundary>
                      {errorCode ? (<PageTemplate>
                          <ErrorHandler errorCode={errorCode}/>
                        </PageTemplate>) : (<Component {...pageProps}/>)}
                    </ErrorBoundary>
                    <ReactQueryDevtools initialIsOpen={false}/>
                  </UserInfoProvider>
                  <ThirdPartyCSSWrapper />
                </main>
              </ThemeProvider>
            </CacheProvider>
          </StyledEngineProvider>
        </Hydrate>
      </QueryClientProvider>
    </>);
}
const __Next_Translate__Page__194fb849e77__ = CustomApp;

    export default __appWithI18n(__Next_Translate__Page__194fb849e77__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  